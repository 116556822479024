@font-face {
  font-family: "PPNeueMontreal Book";
  src: url("PPNeueMontreal-Book.9cbcc72c.otf") format("otf");
}
@font-face {
  font-family: "PPNeueMontreal Medium";
  src: url("PPNeueMontreal-Medium.00c63aa9.otf") format("otf");
}
@font-face {
  font-family: "NeueMontreal Bold";
  src: url("NeueMontreal-Bold.99008d2c.otf") format("otf");
}
/* set base values */
/* type scale */
/* line-height */
/* typography styles */
body {
  font-size: 1em;
  font-family: PPNeueMontreal Book, sans-serif;
  line-height: 1.4;
  color: #000000;
}
h1,
h2,
h3,
h4 {
  font-family: PPNeueMontreal Medium, sans-serif;
  line-height: 1.6;
}
h1 {
  font-size: 2.44140625rem;
  line-height: 2.44140625rem;
  margin-bottom: 3.2rem;
}
h2 {
  font-size: 1.953125rem;
  line-height: 1.953125rem;
  margin-bottom: 2.4rem;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  margin-bottom: 1.6rem;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 1.6rem;
}
.small {
  font-family: PPNeueMontreal Book, sans-serif;
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
}
p {
  margin-bottom: 0.8rem;
}
a {
  font-family: PPNeueMontreal Medium, sans-serif;
  font-size: 0.64rem;
  color: #000000;
  text-decoration: none;
}
.fl,
.FMA {
  font-family: "NeueMontreal Bold", sans-serif;
  font-size: 30px;
}
.fl {
  font-weight: 900;
}
button, .btn {
  border-radius: 46px;
  font-family: PPNeueMontreal Book, sans-serif;
  font-size: 0.8rem;
  border: solid 1.8px #000000;
  background-color: transparent;
}
button:hover, button:active, button:focus, button:visited, .btn:hover, .btn:active, .btn:focus, .btn:visited {
  font-family: PPNeueMontreal Book, sans-serif;
}
label {
  font-family: PPNeueMontreal Medium, sans-serif;
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
}
.container {
  margin: 0 auto;
  padding-right: 1em;
  padding-left: 1em;
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  height: 100%;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #f8efe6;
  z-index: 1;
}
html *::before,
html *::after {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
}
.body-grunge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: url("grunge_texture.812901e2.png");
  z-index: 1;
}
* {
  margin: 0;
  padding: 0;
}
.global {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.global #logo-box {
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-150%);
  z-index: 9;
  z-index: 3;
}
.global .logo {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 1.25rem;
  height: 1.25rem;
}
.global .logo img {
  height: 100%;
  width: 100%;
  margin-left: 2px;
}
.global #illus {
  display: block;
  background: #F8EFE6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 1;
  display: flex;
  transition: padding ease-in-out;
}
.global #illus.move {
  transition: all 1s ease-in-out;
}
.global #illus.move img {
  opacity: 0;
}
.global #illus img {
  display: block;
  max-height: 90%;
  max-width: 90%;
  margin: auto;
  transform: translateY(-20%);
}
.global #box-join-us {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1.25rem;
  opacity: 0;
  transform: translateX(150%);
  cursor: pointer;
  z-index: 4;
  display: flex;
  align-items: center;
  gap: 10px;
}
.global #box-join-us .social-networks {
  display: flex;
  align-items: center;
  gap: 14px;
}
.global #box-join-us * {
  margin: 0;
}
.global #box-join-us .second-join {
  opacity: 0;
  display: none;
  transform: translateX(150%);
}
.global #box-join-us a:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-out;
}
.global .join-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  position: absolute;
  bottom: 5%;
  right: 50%;
  transition: all 0.4s ease-out;
  font-size: 2rem;
  z-index: 9;
  transform: translate(200%, 0);
}
.global .join-box.display {
  transform: translate(50%, 0);
}
.global .join-box .text-sub {
  font-size: 14px;
  font-family: "PPNeueMontreal Book", sans-serif;
  line-height: 1;
  font-weight: 100;
}
.global .join-box p {
  font-family: "PPNeueMontreal Medium", sans-serif;
  margin: 0;
  line-height: 2rem;
}
.global .join-box .thin {
  font-family: "PPNeueMontreal Book", sans-serif;
  font-weight: 100;
}
.global .join-box .logo {
  font-size: 2rem;
  height: 2rem;
}
.global .join-box .btn-join {
  text-align: center;
  margin: 2rem 0;
  cursor: pointer;
}
.fma-coq-head {
  max-width: 40%;
  position: absolute;
  top: 30%;
  left: 49.5%;
  transform: translate(-50%, -50%);
  width: -webkit-fill-available;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}
.fma-big-title {
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.fma-big-title .fma-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 2s;
}
.fma-big-title .fma-animation p {
  margin-bottom: 0;
}
.fma-big-title .fma-animation.scale {
  transform: scale(5);
}
.fma-big-title .fma-animation.scale img {
  display: none;
}
.fma-big-title img {
  height: 42px;
  margin-bottom: 7px;
  margin-left: 1px;
  cursor: pointer;
  z-index: 9999;
}
.fma-subtitle {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25em;
  transition: all cubic-bezier(0.99, 0.01, 0.57, 0.88);
  display: flex;
}
.fma-subtitle span.red {
  color: #E15342;
}
.fma-subtitle img {
  margin: 0;
  height: 1.25em;
  width: 1.25em;
}
.container-fuck-macron {
  position: absolute;
  top: 15%;
  left: 48%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform-origin: center center;
  transform: translate(-50%, -5%) rotate(-21deg);
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}
.container-fuck-macron .box-fuck-macron {
  display: block;
  white-space: nowrap;
  will-change: transform;
}
.container-fuck-macron .box-fuck-macron img {
  width: 40px;
  height: 40px;
}
.container-fuck-macron .box-fuck-macron .row-0,
.container-fuck-macron .box-fuck-macron .row-2 {
  font-weight: 900;
}
.container-fuck-macron .box-fuck-macron .row-3 {
  padding-bottom: 5px;
}
.container-fuck-macron .box-fuck-macron [class*=row-] {
  font-family: "NeueMontreal Bold", sans-serif;
  font-size: 40px;
  margin-bottom: 0;
  line-height: normal;
}
.coq-btn {
  border: none;
  margin: 0;
  padding: 0;
}
.row-0 {
  -webkit-animation: toLeft 3.3s infinite 230ms alternate linear;
          animation: toLeft 3.3s infinite 230ms alternate linear;
}
.row-1 {
  -webkit-animation: toRight 1.1s infinite 500ms alternate linear;
          animation: toRight 1.1s infinite 500ms alternate linear;
}
.row-2 {
  -webkit-animation: toLeft 14.3s infinite 540ms alternate linear;
          animation: toLeft 14.3s infinite 540ms alternate linear;
}
.row-3 {
  -webkit-animation: toRight 12.1s infinite 450ms alternate linear;
          animation: toRight 12.1s infinite 450ms alternate linear;
}
@-webkit-keyframes toLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33%);
  }
}
@keyframes toLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33%);
  }
}
@-webkit-keyframes toRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(33%);
  }
}
@keyframes toRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(33%);
  }
}
.coq-box {
  width: 108px;
  height: 108px;
}
.coq-img {
  margin-left: 5px;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 25px;
}
.logo p {
  margin-bottom: 0;
}
.logo img {
  height: 25px;
}
.etoile {
  height: 0;
  width: 0;
}
.box-star {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg) scale(1.5);
  width: 0;
  z-index: 2;
}
.box-star.display {
  left: 8%;
  width: 90%;
  top: 40%;
}
i {
  font-size: initial;
}
i::before {
  font-family: "FontAwesome";
  font-style: normal;
}
.heartbeat {
  transform: scale(0.8);
  transform-origin: center;
  -webkit-animation: heartbeat 0.35s infinite alternate;
          animation: heartbeat 0.35s infinite alternate;
}
@-webkit-keyframes heartbeat {
  to {
    transform: scale(1.1);
  }
}
@keyframes heartbeat {
  to {
    transform: scale(1.1);
  }
}
.join-us {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  z-index: -1;
  opacity: 0;
  width: 100%;
  font-size: 2rem;
  transition: opacity 1s ease-out;
}
.join-us .social {
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.join-us .social a, .join-us .social i {
  font-size: 2rem;
}
.join-us.display {
  z-index: 9;
  opacity: 1;
}
@media (min-width: 36em) {
  .container {
    max-width: 540px;
  }
  .global #illus img {
    transform: translateY(0);
  }
  .global .join-box {
    bottom: 50%;
    right: 3%;
    transform: translate(200%, 50%);
    font-size: 20px;
  }
  .global .join-box .text-sub {
    font-size: 12px;
  }
  .global .join-box .logo {
    font-size: 20px;
    height: 20px;
  }
  .global .join-box p {
    line-height: 20px;
  }
  .global .join-box.display {
    transform: translate(0, 50%);
  }
  .fma-big-title .fma-animation.scale {
    transform: scale(8);
  }
  .fma-subtitle {
    bottom: 5%;
    font-size: 3rem;
  }
  .box-star {
    transform: translate(-50%, -50%) rotate(-20deg) scale(0.7);
  }
  .box-star.display {
    left: 8%;
    width: 90%;
    top: 67%;
  }
}
@media (min-width: 48em) {
  .fl,
.FMA {
    font-size: 55px;
  }
  .container {
    max-width: 720px;
  }
  .global #logo-box {
    padding: 6px;
  }
  .global .logo {
    font-size: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  .global #illus img {
    max-height: 65%;
    max-width: 65%;
  }
  .fma-coq-head {
    max-width: 10%;
    max-height: 10%;
    top: 8%;
    width: -webkit-fill-available;
  }
  .fma-big-title .fma-animation.scale {
    transform: scale(6);
  }
  .fma-big-title img {
    height: 86px;
    margin-bottom: 20px;
    margin-left: 3px;
  }
  .container-fuck-macron {
    transform: translate(-51%, -50%) rotate(-20deg);
    top: 24%;
  }
  .container-fuck-macron .box-fuck-macron img {
    width: 40px;
    height: 40px;
  }
  .container-fuck-macron .box-fuck-macron [class*=row-] {
    font-size: 40px;
  }
  .join-us {
    bottom: 11%;
    left: 78%;
  }
}
@media (min-width: 64em) {
  .fl,
.FMA {
    font-size: 78px;
  }
  .container {
    max-width: 960px;
  }
  .global #illus img {
    max-height: 75%;
    max-width: 75%;
  }
  .fma-big-title .fma-animation.scale {
    transform: scale(5.5);
  }
  .fma-subtitle {
    bottom: 12%;
    font-size: 4rem;
  }
}
@media (min-width: 75em) {
  .fl,
.FMA {
    font-size: 80px;
  }
  .container {
    max-width: 1140px;
  }
  .global .logo {
    font-size: 2rem;
    height: 2rem;
  }
  .global .logo {
    font-size: 2.75rem;
    height: 2.75rem;
  }
  .global .join-box {
    font-size: 28px;
    right: 2%;
  }
  .global .join-box .text-sub {
    font-size: 14px;
  }
  .global .join-box .logo {
    font-size: 28px;
    height: 28px;
  }
  .global .join-box p {
    line-height: 40px;
  }
  .fma-coq-head {
    max-width: 12%;
    max-height: 12%;
    width: -webkit-fill-available;
  }
  .fma-big-title img {
    height: 85px;
    margin-bottom: 17px;
    margin-left: 4px;
  }
  .container-fuck-macron {
    transform: translate(-52%, -50%) rotate(-20deg);
    top: 32%;
  }
  .container-fuck-macron .box-fuck-macron img {
    width: 60px;
    height: 60px;
  }
  .container-fuck-macron .box-fuck-macron [class*=row-] {
    font-size: 60px;
  }
  .join-us {
    font-size: 2.5rem;
  }
  .join-us .social {
    gap: 2.5rem;
  }
  .join-us .social a, .join-us .social i {
    font-size: 2.5rem;
  }
}
@media (min-width: 110em) {
  body {
    font-size: 1.75em;
  }
  .fl,
.FMA {
    font-size: 135px;
  }
  .global .join-box {
    font-size: 40px;
  }
  .global .join-box .text-sub {
    font-size: 20px;
  }
  .global .join-box .logo {
    font-size: 40px;
    height: 40px;
  }
  .global .join-box p {
    line-height: 40px;
  }
  .fma-big-title img {
    height: 115px;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  .fma-subtitle {
    bottom: 7%;
    font-size: 7rem;
  }
  .container-fuck-macron {
    transform: translate(-52%, -50%) rotate(-20deg);
  }
  .container-fuck-macron .box-fuck-macron img {
    width: 90px;
    height: 90px;
  }
  .container-fuck-macron .box-fuck-macron [class*=row-] {
    font-size: 90px;
  }
  .join-us {
    font-size: 3.5rem;
  }
  .join-us .social {
    gap: 3.5rem;
  }
  .join-us .social a, .join-us .social i {
    font-size: 3.5rem;
  }
}
/*# sourceMappingURL=index.e0da08e0.css.map */
