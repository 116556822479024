// Container Rules

.container {
    margin: 0 auto;
    padding-right: 1em;
    padding-left: 1em;
    width: 100%;
    height: 100%;

    @include mq(sm) {
        max-width: $col-sm;
    }

    @include mq(md) {
        max-width: $col-md;
    }

    @include mq(lg) {
        max-width: $col-lg;
    }

    @include mq(xl) {
        max-width: $col-xl;
    }
}