// Typography Rules

$font-text: 'PPNeueMontreal Book', sans-serif;
$font-heading: 'PPNeueMontreal Medium', sans-serif;
$font-fuckMacron: 'NeueMontreal Bold', sans-serif;

/* set base values */
$text-base-size: 1em; // ( =16px )
$text-scale-ratio: 1.25;

/* type scale */
$text-xs: calc(1rem / ($text-scale-ratio * $text-scale-ratio));
$text-sm: calc(1rem / $text-scale-ratio);
$text-md: calc(1rem * $text-scale-ratio);
$text-lg: calc(1rem * $text-scale-ratio * $text-scale-ratio);
$text-xl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio
);
$text-xxl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
    $text-scale-ratio
);
$text-xxxl: calc(
  1rem * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio *
    $text-scale-ratio * $text-scale-ratio
);

/* line-height */
$heading-line-height: 1.6;
$body-line-height: 1.4;

// /* responsive type */
// $minScreen: 36rem;
// $maxScreen: 50rem;
// $minFont: 0.8rem;
// $maxFont: 2rem;

/* typography styles */

body {
  font-size: $text-base-size;
  font-family: #{$font-text};
  line-height: $body-line-height;
  color: #{$color-text};

  @include mq(xxl) {
    font-size: 1.75em;
  }
}

h1,
h2,
h3,
h4 {
  font-family: #{$font-heading};
  line-height: $heading-line-height;
}

h1 {
  font-size: $text-xxl;
  line-height: $text-xxl;
  margin-bottom: #{$space-xxl};
}

h2 {
  font-size: $text-xl;
  line-height: $text-xl;
  margin-bottom: #{$space-xl};
}

h3 {
  font-size: $text-lg;
  line-height: $text-lg;
  margin-bottom: #{$space-lg};
}

h4 {
  font-size: $text-md;
  line-height: $text-md;
  margin-bottom: #{$space-lg};
}

.small {
  font-family: #{$font-text};
  font-size: #{$text-sm};
  margin-bottom: #{$space-sm};
}

p {
  margin-bottom: #{$space-md};
}

a {
  font-family: #{$font-heading};
  font-size: #{$text-xs};
  color: #{$color-text};
  text-decoration: none;
}
.fl,
.FMA {
  font-family: $font-fuckMacron;
  font-size: 30px;

  @include mq(md) {
      font-size: 55px;
  }

  @include mq(lg) {
      font-size: 78px;
  }

  @include mq(xl) {
      font-size: 80px;
  }
  @include mq(xxl) {
      font-size: 135px;
  }
}
.fl {
  font-weight: 900;
}
