// Import Fonts

@font-face {
  font-family: 'PPNeueMontreal Book';
  src: url('/src/fonts/PPNeueMontreal-Book.otf') format('otf');
}

@font-face {
  font-family: 'PPNeueMontreal Medium';
  src: url('/src/fonts/PPNeueMontreal-Medium.otf') format('otf');
}
@font-face {
  font-family: 'NeueMontreal Bold';
  src: url('/src/fonts/NeueMontreal-Bold.otf') format('otf');
}
