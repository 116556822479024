button, .btn {
  border-radius: $radius-box;
  font-family: #{$font-text};
  font-size: #{$text-sm};
  border: solid $border-box $color-border;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    font-family: #{$font-text};
  }
}
