@charset 'utf-8';
@use 'sass:meta';

//Abstracts
@import 'abstracts/__abstracts-dir';

//Base Styles
@import 'base/__base-dir';

//Components
@import 'components/__components-dir';

//Layout
@import 'layouts/__layouts-dir';

// Reset
* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  height: 100%;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  // @include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: #f8efe6;
    z-index: 1;
  }

  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
  }
}

.body-grunge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: url('/src/imgs/grunge_texture.png');
  z-index: 1;
}

// Reset
* {
  margin: 0;
  padding: 0;
}

// Site
.global {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;

  #logo-box {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-150%);
    z-index: 9;
    z-index: 3;

    @include mq(md) {
      padding: 6px;
    }

  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: 1.25rem;
    height: 1.25rem;

    img {
      height: 100%;
      width: 100%;
      margin-left: 2px;
    }

    @include mq(md) {
      font-size: 1rem;
      height: 1rem;
      margin-left: .5rem;
    }
    @include mq(xl) {
      font-size: 2rem;
      height: 2rem;
    }
    @include mq(xl) {
      font-size: 2.75rem;
      height: 2.75rem;
    }
  }

  #illus {
    display: block;
    background: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
    width: 0;
    height: 0;
    z-index: 1;
    display: flex;
    transition: padding ease-in-out;
    
    &.move {
      transition: all 1s ease-in-out;
      img {
        opacity: 0;
      }
    }

    img {
      display: block;
      max-height: 90%;
      max-width: 90%;
      margin: auto;
      transform: translateY(-20%);

      @include mq(sm) {
        transform: translateY(0);
      }
      @include mq(md) {
        max-height: 65%;
        max-width: 65%;
      }
      @include mq(lg) {
        max-height: 75%;
        max-width: 75%;
      }
    }
  }

  #box-join-us {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1.25rem;
    opacity: 0;
    transform: translateX(150%);
    cursor: pointer;
    z-index: 4;
    display: flex;
    align-items: center;
    gap: 10px;

    .social-networks {
      display: flex;
      align-items: center;
      gap: 14px;
    }

    * {
      margin: 0;
    }

    .second-join {
      opacity: 0;
      display: none;
      transform: translateX(150%);
    }
    a:hover {
      transform: scale(1.2);
      transition: transform .2s ease-out;
    }
  }

  .join-box {
    width: fit-content;
    background: transparent;
    position: absolute;
    bottom: 5%;
    right: 50%;
    transition: all 0.4s ease-out;
    // opacity: 0;
    font-size : 2rem;
    z-index: 9;
    transform: translate(200%, 0);
    
    
    &.display {
      transform: translate(50%, 0);
    }
    
    .text-sub {
      font-size: 14px;
      font-family: $font-text;
      line-height: 1;
      font-weight: 100;
    }
    
    p {
      font-family: $font-heading;
      margin: 0;
      line-height: 2rem;
    }
    .thin {
      font-family: $font-text;
      font-weight: 100;
    }

    .logo {
      font-size: 2rem;
      height: 2rem;
    }
    
    .btn-join {
      text-align: center;
      margin: 2rem 0;
      cursor: pointer;
    }
    @include mq(sm) {
      bottom: 50%;
      right: 3%;
      transform: translate(200%, 50%);
      font-size: 20px;
      
      .text-sub {
        font-size: 12px;
      }

      .logo {
        font-size: 20px;
        height: 20px;
      }

      p {
        line-height: 20px;
      }

      &.display {
        transform: translate(0, 50%);

      }
    }
    @include mq(xl) {
      font-size: 28px;
      right: 2%;
      
      .text-sub {
        font-size: 14px;
      }

      .logo {
        font-size: 28px;
        height: 28px;
      }

      p {
        line-height: 40px;
      }
    }
    @include mq(xxl) {
      font-size: 40px;
      
      .text-sub {
        font-size: 20px;
      }

      .logo {
        font-size: 40px;
        height: 40px;
      }

      p {
        line-height: 40px;
      }
    }
  }
}

.fma-coq-head {
  max-width: 40%;
  position: absolute;
  top: 30%;
  left: 49.5%;
  transform: translate(-50%,-50%);
  width: -webkit-fill-available;
  z-index: -1;
  opacity: 0;
  cursor: pointer;

  @include mq(md) {
    max-width: 10%;
    max-height: 10%;
    top: 8%;
    width: -webkit-fill-available;
  }
  @include mq(xl) {
    max-width: 12%;
    max-height: 12%;
    width: -webkit-fill-available;
  }
}

.fma-big-title {
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;

  .fma-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 2s;

    p {
      margin-bottom: 0;
    }
    &.scale {
      transform: scale(5);

      img {
        display: none;
      }
    
      @include mq(sm) {
        transform: scale(8);
      }
    
      @include mq(md) {
        transform: scale(6);
      }
    
      @include mq(lg) {
          transform: scale(5.5);
      }
    }
  }

  img {
    height: 42px;
    margin-bottom: 7px;
    margin-left: 1px;
    cursor: pointer;
    z-index: 9999;

    @include mq(md) {
      height: 86px;
      margin-bottom: 20px;
      margin-left: 3px;
    }
  
    @include mq(xl) {
      height: 85px;
      margin-bottom: 17px;
      margin-left: 4px;
    }
    @include mq(xxl) {
      height: 115px;
      margin-bottom: 20px;
      margin-left: 5px;
    }
  }

}
.fma-subtitle {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.25em;
  transition: all cubic-bezier(.99, .01, .57, .88);
  display: flex;

  span.red {
    color: $red;
  }

  img {
    margin: 0;
    height: 1.25em;
    width: 1.25em;
  }

  @include mq(sm) {
    bottom: 5%;
    font-size: 3rem;
  }
  @include mq(lg) {
    bottom: 12%;
    font-size: 4rem;
  }
  @include mq(xxl) {
    bottom: 7%;
    font-size: 7rem;
  }
}

.container-fuck-macron {
  position: absolute;
  top: 15%;
  left: 48%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform-origin: center center;
  transform: translate(-50%, -5%) rotate(-21deg);
  overflow: hidden;
  opacity: 0;
  z-index: -1;

  .box-fuck-macron {
    display: block;
    white-space: nowrap;
    will-change: transform;

    img {
      width: 40px;
      height: 40px;
    }

    .row-0,
    .row-2 {
      font-weight: 900;
    }

    .row-3 {
      padding-bottom: 5px;
    }

    [class*='row-'] {
      font-family: $font-fuckMacron;
      font-size: 40px;
      margin-bottom: 0;
      line-height: normal;
    }
  }
  @include mq(md) {
    transform: translate(-51%, -50%) rotate(-20deg);
    top: 24%;

    .box-fuck-macron {
      img {
        width: 40px;
        height: 40px;
      }
  
      [class*='row-'] {
        font-size: 40px;
      }
    }
  }
  @include mq(xl) {
    transform: translate(-52%, -50%) rotate(-20deg);
    top: 32%;
    .box-fuck-macron {
      img {
        width: 60px;
        height: 60px;
      }
  
      [class*='row-'] {
        font-size: 60px;
      }
    }
  }
  @include mq(xxl) {
    transform: translate(-52%, -50%) rotate(-20deg);
    .box-fuck-macron {
      img {
        width: 90px;
        height: 90px;
      }
  
      [class*='row-'] {
        font-size: 90px;
      }
    }
  }
}

.coq-btn {
  border: none;
  margin: 0;
  padding: 0;
}

@for $i from 0 to 4 {
  @if $i%2==0 {
    .row-#{$i} {
      animation: toLeft #{random(13) * 1.1}s infinite #{random(87) * 10 + 100}ms alternate linear;
    }
  }

  @else {
    .row-#{$i} {
      animation: toRight #{random(13) * 1.1}s infinite #{random(87) * 10 + 100}ms alternate linear;
    }
  }
}

@keyframes toLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-33%);
  }
}

@keyframes toRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(33%);
  }
}

.coq-box {
  width: 108px;
  height: 108px;
}
.coq-img {
  margin-left: 5px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 25px;

  p {
    margin-bottom: 0;
  }

  img {
    height: 25px;
  }

}

.etoile {
  height: 0;
  width: 0;
}

.box-star {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg) scale(1.5);
  width: 0;
  z-index: 2;

  &.display {
    left: 8%;
    width: 90%;
    top: 40%;

  }
  @include mq(sm) {

    transform: translate(-50%, -50%) rotate(-20deg) scale(0.7);
    &.display {
      left: 8%;
      width: 90%;
      top: 67%;
    }
  }
}

i {
  font-size: initial;
  &::before {
    font-family: "FontAwesome";
    font-style: normal;
  }
}

.heartbeat {
  transform: scale(0.8);
  transform-origin: center;
  animation: heartbeat .35s infinite alternate;
}

@keyframes heartbeat{
	to { transform: scale(1.1); }
}

.join-us {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  z-index: -1;
  opacity: 0;
  width: 100%;
  font-size: 2rem;
  transition: opacity 1s ease-out;

  .social {
    display: flex;
    gap: 2rem;
    justify-content: center;

    a, i {
      font-size: 2rem;
    }
  }

  &.display {
    z-index: 9;
    opacity: 1;
  }

  @include mq(md) {
    bottom: 11%;
    left: 78%;
  }
  @include mq(xl) {
    font-size: 2.5rem;

    .social {
      gap: 2.5rem;
  
      a, i {
        font-size: 2.5rem;
      }
    }
  }
  @include mq(xxl) {
    font-size: 3.5rem;

    .social {
      gap: 3.5rem;
  
      a, i {
        font-size: 3.5rem;
      }
    }
  }
}